<template>
  <el-dialog
    v-model="dialogVisible"
    :title="t('project.transfer')"
    width="800px"
    @close="closeWin">
    <div class="form">
      <el-form
        ref="form"
        :model="formData.form"
        :rules="formData.rules"
        label-width="185px"
        label-position="right">
        <el-form-item :label="t('project.original_enterprise')">
          {{ enterprise || '-' }}
        </el-form-item>
        <el-form-item :label="t('project.transferred_enterprise')" prop="enterprise_id">
          <el-select
            v-model="formData.form.enterprise_id"
            clearable
            filterable
            @change="changeEnterprise"
            :placeholder="t('form.selectPlaceholder')">
            <el-option
              v-for="(item, index) in enterpriseList"
              :key="index"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item :label="t('project.transferred_enterprise_user')" prop="user_id">
          <el-select
            v-model="formData.form.user_id"
            :disabled="!!!formData.form.enterprise_id"
            :placeholder="t('form.selectPlaceholder')"
            clearable
            filterable>
            <el-option
              v-for="(item, index) in enterpriseUserList"
              :key="index"
              :label="item.label"
              :value="item.value" />
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button @click="closeWin">{{ t('form.cancel') }}</el-button>
      <el-button type="primary" :loading="loading" @click="onSubmit()">{{ t('form.submit') }}</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, reactive, inject } from 'vue'
import { ElMessage } from 'element-plus'
import { AuthManagement } from '@/utils/api'
import { getEnterpriseList, getUserList } from '@/utils/common'

const t = inject('t')
const dialogVisible = ref(false) // 弹框
const enterpriseList = ref([]) // 选择框企业列表
const enterpriseUserList = ref([]) //移交企业用户列表
const enterprise = ref('') // 原企业名称
const edit_id = ref('') // 项目id
const loading = ref(false) // 提交按钮loading
const form = ref();
const formData = reactive({
  form: {},
  rules: {
    enterprise_id: [{ required: true, message: t('form.emptyErrMsg') }],
    user_id: [{ required: true, message: t('form.emptyErrMsg') }]
  }
})

const emit = defineEmits(['getList'])
// 打开弹框
const openWin = async (data) => {
  dialogVisible.value = true;
  enterprise.value = data.name
  edit_id.value = data.id
  enterpriseList.value = await getEnterpriseList()
}

// 关闭弹框
const closeWin = () => {
  form.value.resetFields()
  form.value.clearValidate()
  dialogVisible.value = false;
}

// 移交企业变动触发
const changeEnterprise = async () => {
  formData.form.user_id = ''
  enterpriseUserList.value = await getUserList({
    is_paginate: false,
    filter_role_alias: 'enterprise',
    filter_enterprise_id: formData.form.enterprise_id
  })
}

// 提交转接
const onSubmit = () => {
  form.value.validate(valid => {
    if (!!!valid) return
    loading.value = true
    AuthManagement.ProjectRelocation(edit_id.value, {
      ...formData.form
    }).then(res => {
      ElMessage.success(t('project.transfer_successful'))
      loading.value = false
      closeWin()
      emit('getList')
    }).catch(error => {
      loading.value = false
    })
  })
}

defineExpose({
  openWin: openWin
})
</script>