<template>
  <div class="main">
    <!-- 审计管理 -->
    <custem-table :tableInfo="tableInfo" :tableLoading="tableLoading" @getList="getList"></custem-table>
    <!-- 移交项目弹框 -->
    <Deliver ref="deliver" @getList="getList" />
  </div>
</template>

<script setup>
import { AuthManagement } from '@/utils/api'
import { onMounted, reactive, ref, inject } from 'vue'
import Deliver from './components/deliver'

const deliver = ref()
const t = inject('t')
const tableLoading = ref(false)

// 项目移交
const onDelive = (data) => {
  if (!!!deliver.value) return
  deliver.value.openWin(data)
}

const tableInfo = reactive({
  header: [
    { // 项目名称
      key: 'name',
      label: t('project.project_mail'),
      width: '320px'
    },
    { // 项目邮箱
      key: 'mail',
      label: t('project.project_mail'),
      minWidth: '320px'
    },
    { // 所属企业
      key: 'phone',
      label: t('routes.enterprise'),
      minWidth: '320px',
      custem: (data) => {
        return data.enterprise ? data.enterprise.name : '-'
      }
    },
    { // 创建时间
      sortable: true,
      sortBy: (data) => {
        return data.created_at
      },
      label: t('table.creation_time'),
      key: 'created_at',
      width: '180px'
    },
    { // 移交状态
      label: t('project.transfer_status'),
      width: '180px',
      sortable: true,
      sortBy: (data) => {
        return data.params.relocation
      },
      custem: (data) => {
        return data.params && data.params.relocation ? `<span class="finished">${t('assign.handed')}</span>` : `<span class="faild">${t('assign.unhanded')}</span>`
      }
    },
    {
      label: t('table.action'),
      width: '90px',
      fixed: 'right',
      handleFun: [
        {
          name: t('project.transfer'),
          fn: onDelive,
          disabled: (data) => {
            return !!(data.params && data.params.relocation)
          }
        }
      ]
    }
  ]
})

const getList = async (search = {}) => {
  tableLoading.value = true
  try {
    const res = await AuthManagement.ProjectList({
      filter_category: 0,
      ...search
    })
    tableInfo.data = res.items
    tableInfo.totalPage = res.total
    tableInfo.pageSize = res.per_page
    tableLoading.value = false
  } catch (error) {
    tableLoading.value = false
  }
}

onMounted(() => {
  getList()
})
</script>